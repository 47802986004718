// src/components/Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SignInModal from './SignInModal';
import './Header.css';
import { getAuth, signOut } from "firebase/auth"; // Import Firebase sign-out
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = () => {
    setShowModal(true);
  };

  const handleSignOut = () => {
    const auth = getAuth(); // Get Firebase auth instance
    signOut(auth)
      .then(() => {
        // Sign-out successful, navigate to home or login
        console.log("User signed out successfully.");
        navigate('/');
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error signing out: ", error);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="main-header bg-baseblue shadow-md">
      <div className='bg-baseblue flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4'>
        {/* Left Side: Logo */}
        <div className="logo-container">
          <a href="/" className="flex-shrink-0">
            <h1 className='text-white text-3xl font-bold'>Quizzo</h1>
          </a>
        </div>

        {/* Navigation Links */}
        <nav className="nav-links flex items-center space-x-4">
          {/* <a href="add" className="nav-link p-4 text-white hover:bg-highlightblue">Add</a> */}
          {/* <a href="canvas" className="nav-link p-4 text-white hover:bg-highlightblue">Canvas</a> */}
          <a href="codesandbox" className="nav-link p-4 text-white hover:bg-highlightblue">CodeSandbox</a>
          <a href="codeeditor" className="nav-link p-4 text-white hover:bg-highlightblue">CodeEditor</a>
          {/* <a href="flashpdf" className="nav-link p-4 text-white hover:bg-highlightblue">FlashPDF</a> */}
          {/* <a href="flashcards" className="nav-link p-4 text-white hover:bg-highlightblue">Flashcards</a> */}
          <a href="study" className="nav-link p-4 text-white hover:bg-highlightblue">Study</a>

          {/* <a href="#" className="nav-link p-4 text-white hover:bg-highlightblue">Courses</a> */}
          {/* <a href="#" className="nav-link p-4 text-white hover:bg-highlightblue">Practice</a> */}
          {/* <a href="#" className="nav-link p-4 text-white hover:bg-highlightblue">Roadmap</a>  */}

{/* Sign-in / Sign-out logic */}
{currentUser ? (
  <div className="relative">
    {currentUser.profileImageURL ? (
      <img 
        src={currentUser.profileImageURL} 
        alt="User" 
        className="user-image" 
        onClick={toggleDropdown} // Clickable profile image for dropdown
      />
    ) : (
      <button onClick={toggleDropdown} className="nav-link button">Profile</button>
    )}

    {/* Dropdown menu for user page and sign-out */}
    {isDropdownOpen && (
      <div className="dropdown-menu">
        <Link to="/profile" className="dropdown-item">User Profile</Link> {/* Updated Link */}
        <button onClick={handleSignOut} className="dropdown-item">
          Sign Out
        </button>
      </div>
    )}
  </div>
) : (
  <button onClick={handleSignIn} className="nav-link button">Sign In</button>
)}
        </nav>

        {/* Sign-in Modal */}
        <SignInModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </header>
  );
};

export default Header;
