// FlashcardPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import Flashcard from '../components/Flashcard';
import './FlashcardPage.css';

const FlashcardPage = () => {
  const { unitId, deckId } = useParams();
  const [flashcards, setFlashcards] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        const flashcardsCollection = collection(db, `units/${unitId}/decks/${deckId}/flashcards`);
        const flashcardsSnapshot = await getDocs(flashcardsCollection);
        const flashcardsList = flashcardsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            front: data.flashcard_front,
            back: data.flashcard_back,
          };
        });
        setFlashcards(flashcardsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching flashcards:', error);
        setLoading(false);
      }
    };

    fetchFlashcards();
  }, [unitId, deckId]);

  if (loading) {
    return <p>Loading flashcards...</p>;
  }

  return (
    <div className="flashcard-page">
      <header className="flashcard-header">
        <h1>Flashcards</h1>
      </header>

      <main className="flashcard-main">
        {flashcards.length > 0 ? (
          <Flashcard flashcards={flashcards} />
        ) : (
          <p>No flashcards available.</p>
        )}
      </main>

    </div>
  );
};

export default FlashcardPage;
