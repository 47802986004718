import React from 'react';

const ProPage = () => {
  return (
    <div style={styles.container}>
      <h1>Choose Your Pro Plan</h1>
      <div style={styles.plans}>
        <div style={styles.plan}>
          <h3>Basic Plan</h3>
          <p>$10/month</p>
          <button style={styles.button}>Buy Now</button>
        </div>
        <div style={styles.plan}>
          <h3>Advanced Plan</h3>
          <p>$20/month</p>
          <button style={styles.button}>Buy Now</button>
        </div>
        <div style={styles.plan}>
          <h3>Premium Plan</h3>
          <p>$30/month</p>
          <button style={styles.button}>Buy Now</button>
        </div>
      </div>
    </div>
  );
};

// Example inline styles for the ProPage
const styles = {
  container: {
    padding: '50px',
    textAlign: 'center',
  },
  plans: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  plan: {
    border: '1px solid #ccc',
    padding: '20px',
    borderRadius: '5px',
    width: '200px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#0275d8',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ProPage;
