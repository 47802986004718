// src/pages/PDFViewerPage.js
import React, { useState, useRef } from 'react';
import ExtractedTextComponent from './flashcardpagecomponents/ExtractedTextComponent';
import PDFViewerComponent from './flashcardpagecomponents/PDFViewerComponent';
import FlashcardEntryComponent from './flashcardpagecomponents/FlashcardEntryComponent';
import { Document, Page, pdfjs } from 'react-pdf';

// No need to import workerSrc manually; set the workerSrc using the CDN or local path
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFViewerPage = () => {
    const [selectedText, setSelectedText] = useState('');
    const [flashcardContent, setFlashcardContent] = useState('');
    
    // State for column widths
    const [leftWidth, setLeftWidth] = useState(450); // Default width
    const [middleWidth, setMiddleWidth] = useState(800); // Default width
  
    // Refs for the resizable dividers
    const leftDividerRef = useRef(null);
    const middleDividerRef = useRef(null);
  
    const handleTextSelect = () => {
      const text = window.getSelection().toString();
      setSelectedText(text);
    };
  
    const handleMouseDown = (e, direction) => {
      const startX = e.clientX;
      const startLeftWidth = leftWidth;
      const startMiddleWidth = middleWidth;
  
      const handleMouseMove = (e) => {
        const newLeftWidth = startLeftWidth + (e.clientX - startX);
        if (direction === 'left') {
          setLeftWidth(newLeftWidth);
        } else {
          const newMiddleWidth = startMiddleWidth + (e.clientX - startX);
          setMiddleWidth(newMiddleWidth);
        }
      };
  
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };
  
    return (
      <div style={{ display: 'grid', gridTemplateColumns: `${leftWidth}px ${middleWidth}px auto`, height: '100vh', position: 'relative' }}>
        <div style={{ overflow: 'auto', padding: '1rem', borderRight: '1px solid #ccc' }}>
          <ExtractedTextComponent selectedText={selectedText} />
        </div>
  
        <div style={{ overflow: 'auto', padding: '1rem' }} onMouseUp={handleTextSelect}>
          <PDFViewerComponent />
        </div>
  
        <div style={{ overflow: 'auto', padding: '1rem', borderLeft: '1px solid #ccc' }}>
          <FlashcardEntryComponent flashcardContent={flashcardContent} setFlashcardContent={setFlashcardContent} />
        </div>
  
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: `${leftWidth}px`,
            height: '100%',
            width: '5px',
            cursor: 'col-resize',
            backgroundColor: '#ccc'
          }}
          onMouseDown={(e) => handleMouseDown(e, 'left')}
          ref={leftDividerRef}
        ></div>
  
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: `${leftWidth + middleWidth}px`,
            height: '100%',
            width: '5px',
            cursor: 'col-resize',
            backgroundColor: '#ccc'
          }}
          onMouseDown={(e) => handleMouseDown(e, 'middle')}
          ref={middleDividerRef}
        ></div>
      </div>
    );
  };
  
  export default PDFViewerPage;