// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import UnitsPage from './pages/UnitsPage';

import DashboardPage from './pages/DashboardPage';
import FlashCardStudyScreen from './pages/FlashCardStudyScreen';
import FlashcardReviewPage from './pages/FlashcardReviewPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import FlashcardPage from './pages/FlashcardPage';  // Ensure the correct path to FlashcardPage
import PDFViewerPage from './pages/PDFViewerPage';  // Ensure the correct path to FlashcardPage
import FlashcardCreatorPage from './pages/FlashcardCreatorPage';
import FlashPDFPage from './pages/FlashPDFPage';
import CodeEditorPage from './pages/CodeEditorPage';
import ProblemPage from './pages/ProblemPage';
import CodeSandboxPage from './pages/CodeSandboxPage';
import ZoomableCanvasPage from './pages/ZoomableCanvasPage';

import Navbar from './components/Navbar';
import StudyPage from './pages/StudyPage';
import EditUnitPage from './pages/EditUnitPage';
import EditDeckPage from './pages/EditDeckPage'; // Create this component similarly
import Header from './components/Header';

import UserInfoFormPage from './pages/UserInfoFormPage';
import UserProfilePage from './pages/UserProfilePage';

import ProBanner from './components/ProBanner';
import ProPage from './pages/ProPage';
import OriginalFlashCardPage from './pages/OriginalFlashCardPage';

// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { gtag } from './utils/gtag';

// function usePageViews() {
//   const location = useLocation();

//   useEffect(() => {
//     // Check if gtag is available on the window object
//     if (typeof window.gtag !== 'undefined') {
//       window.gtag('config', 'G-XZD7YV2PE7', {
//         page_path: location.pathname,
//       });
//     }
//   }, [location]);
// }

function App() {
  // usePageViews();

  return (
    
    <AuthProvider>
      <Router>
{/* <ProBanner /> */}
    {/* <Navbar> */}
    <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/profile" element={<UserProfilePage />} />
          <Route path="/user-info-form" element={<UserInfoFormPage />} />

          <Route path="/study" element={<StudyPage />} />
          <Route path="/study/edit-unit/:unitId" element={<EditUnitPage />} />
          <Route path="/study/edit-deck/:unitId/:deckId" element={<EditDeckPage />} />


          <Route path="/codeeditor" element={<CodeEditorPage />} />
          <Route path="/problems/:problemId" element={<ProblemPage />} />
          <Route path="/codesandbox" element={<CodeSandboxPage />} />

          <Route path="/study/units/:unitId/decks/:deckId" element={<FlashcardPage />} />


          <Route path="/flashcards" element={<OriginalFlashCardPage />} />
          <Route path="/pdfviewer" element={<PDFViewerPage />} />
          <Route path="/flashcreator" element={<FlashcardCreatorPage />} />
          {/* <Route path="/flashpdf" element={<FlashPDFPage />} /> */}
          <Route path="/study/flash-pdf/:unitId/:deckId" element={<FlashPDFPage />} />

          <Route path="/canvas" element={<ZoomableCanvasPage />} />
          <Route path="/pro" element={<ProPage />} />


          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/user" element={<UserInfoFormPage />} />
          <Route path="/units" element={<UnitsPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/study/:unitCode" element={<FlashCardStudyScreen />} />
          <Route path="/review/:deckId" element={<FlashcardReviewPage />} />


          {/* <Route path="/dashboard" element={<PrivateRoute element={DashboardPage} />} /> */}
        </Routes>
        {/* </Navbar> */}
      </Router>
    </AuthProvider>
  );
}

export default App;
