// src/pages/EditUnitPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs, addDoc } from 'firebase/firestore';
import './EditUnitPage.css'; // Regular CSS import

const EditUnitPage = () => {
  const { unitId } = useParams();
  const navigate = useNavigate();
  const [unit, setUnit] = useState(null);
  const [decks, setDecks] = useState([]);
  const [newDeckName, setNewDeckName] = useState('');

  useEffect(() => {
    const fetchUnitAndDecks = async () => {
      try {
        const unitRef = doc(db, 'units', unitId);
        const unitSnap = await getDoc(unitRef);
        if (unitSnap.exists()) {
          setUnit({ unitId, ...unitSnap.data() });
        }

        const decksRef = collection(db, `units/${unitId}/decks`);
        const decksSnap = await getDocs(decksRef);
        const decksData = decksSnap.docs.map((deckDoc) => ({
          deckId: deckDoc.id,
          ...deckDoc.data(),
        }));
        setDecks(decksData);
      } catch (error) {
        console.error('Error fetching unit and decks:', error);
      }
    };

    fetchUnitAndDecks();
  }, [unitId]);

  const handleAddDeck = async () => {
    if (newDeckName.trim() === '') {
      alert('Please enter a deck name.');
      return;
    }

    try {
      const decksRef = collection(db, `units/${unitId}/decks`);
      const newDeckRef = await addDoc(decksRef, {
        deck_name: newDeckName,
      });

      setDecks((prevDecks) => [
        ...prevDecks,
        { deckId: newDeckRef.id, deck_name: newDeckName },
      ]);

      setNewDeckName('');
    } catch (error) {
      console.error('Error adding deck:', error);
    }
  };

  const handleEditDeck = (deckId) => {
    navigate(`/study/edit-deck/${unitId}/${deckId}`);
  };

  const handleAddPDF = (deckId) => {
    navigate(`/study/flash-pdf/${unitId}/${deckId}`);
  };
  

  if (!unit) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="edit-unit-container">
      <h1 className="title" style={{ color: 'white', fontSize: '28px' }}>Edit Unit: {unit.unit_full}</h1>

      <h2 className="subtitle" style={{ color: 'white', fontSize: '20px' }}>Decks</h2>
      <ul className="deck-list">
        {decks.map((deck) => (
          <li key={deck.deckId} className="deck-item">
        <span>{deck.deck_name}</span>
          <button
            onClick={() => handleEditDeck(deck.deckId)}
            className="edit-button"
          >
            Edit Deck
          </button>
          <button
            onClick={() => handleAddPDF(deck.deckId)}
            className="add-pdf-button"
          >
            Add PDF
          </button>
      </li>

        ))}
      </ul>

      <h3 className="subtitle" style={{ color: 'white', fontSize: '20px' }}>Add New Deck</h3>
      <div className="form-group">
        <input
          type="text"
          value={newDeckName}
          onChange={(e) => setNewDeckName(e.target.value)}
          placeholder="Deck Name"
          className="input"
        />
        <button onClick={handleAddDeck} className="add-button">
          Add Deck
        </button>
      </div>
    </div>
  );
};

export default EditUnitPage;
