// src/pages/EditDeckPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import './EditDeckPage.css'; // Regular CSS import

const EditDeckPage = () => {
  const { unitId, deckId } = useParams();
  const [deck, setDeck] = useState(null);
  const [flashcards, setFlashcards] = useState([]);
  const [newFlashcard, setNewFlashcard] = useState({ flashcard_front: '', flashcard_back: '' });

  useEffect(() => {
    const fetchDeckAndFlashcards = async () => {
      try {
        // Fetch the deck document
        const deckRef = doc(db, `units/${unitId}/decks`, deckId);
        const deckSnap = await getDoc(deckRef);
        if (deckSnap.exists()) {
          setDeck({ deckId, ...deckSnap.data() });
        } else {
          console.error('Deck does not exist');
        }

        // Fetch the flashcards subcollection
        const flashcardsCollectionRef = collection(
          db,
          `units/${unitId}/decks/${deckId}/flashcards`
        );
        const flashcardsSnap = await getDocs(flashcardsCollectionRef);
        const flashcardsList = flashcardsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFlashcards(flashcardsList);
      } catch (error) {
        console.error('Error fetching deck and flashcards:', error);
      }
    };

    fetchDeckAndFlashcards();
  }, [unitId, deckId]);

  const handleAddFlashcard = async () => {
    if (!newFlashcard.flashcard_front || !newFlashcard.flashcard_front) {
      alert('Please enter both question and answer.');
      return;
    }

    try {
      const flashcardsCollectionRef = collection(
        db,
        `units/${unitId}/decks/${deckId}/flashcards`
      );
      const newFlashcardDocRef = await addDoc(flashcardsCollectionRef, {
        flashcard_front: newFlashcard.flashcard_front,
        flashcard_back: newFlashcard.flashcard_back,
      });

      // Update the flashcards state
      setFlashcards((prevFlashcards) => [
        ...prevFlashcards,
        {
          id: newFlashcardDocRef.id,
          flashcard_front: newFlashcard.flashcard_front,
          flashcard_back: newFlashcard.flashcard_back,
        },
      ]);

      // Reset input fields
      setNewFlashcard({ flashcard_front: '', flashcard_back: '' });
    } catch (error) {
      console.error('Error adding flashcard:', error);
    }
  };

  const handleDeleteFlashcard = async (flashcardId) => {
    try {
      await deleteDoc(
        doc(db, `units/${unitId}/decks/${deckId}/flashcards`, flashcardId)
      );
      setFlashcards((prevFlashcards) =>
        prevFlashcards.filter((flashcard) => flashcard.id !== flashcardId)
      );
    } catch (error) {
      console.error('Error deleting flashcard:', error);
    }
  };

  if (!deck) {
    return <div className="loading">Loading deck...</div>;
  }

  return (
    <div className="edit-deck-container">
      <h1 className="title" style={{ color: 'white', fontSize: '28px' }} >Edit Deck: {deck.deck_name}</h1>

      <h2 className="subtitle" style={{ color: 'white', fontSize: '20px' }} >Flashcards</h2>
      <ul className="flashcard-list">
        {flashcards.map((flashcard) => (
          <li key={flashcard.id} className="flashcard-item">
            <div>
              <strong>Q:</strong> {flashcard.flashcard_front}
              <br />
              <strong>A:</strong> {flashcard.flashcard_back}
            </div>
            <button
              onClick={() => handleDeleteFlashcard(flashcard.id)}
              className="delete-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>

      <h3 className="subtitle" style={{ color: 'white', fontSize: '20px' }}>Add New Flashcard</h3>
      <div className="form-group">
        <input
          type="text"
          placeholder="Question"
          value={newFlashcard.flashcard_front}
          onChange={(e) =>
            setNewFlashcard({ ...newFlashcard, flashcard_front: e.target.value })
          }
          className="input"
        />
        <input
          type="text"
          placeholder="Answer"
          value={newFlashcard.flashcard_back}
          onChange={(e) =>
            setNewFlashcard({ ...newFlashcard, flashcard_back: e.target.value })
          }
          className="input"
        />
        <button onClick={handleAddFlashcard} className="add-button">
          Add Flashcard
        </button>
      </div>
    </div>
  );
};

export default EditDeckPage;
