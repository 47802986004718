// src/App.js
import React from 'react';
import ZoomableCanvas from '../components/ZoomableCanvas';



function ZoomableCanvasPage() {
  return (
    <div style={{ padding: '20px' }}>
      {/* <h1>React Code Sandbox</h1> */}
      <ZoomableCanvas />
    </div>
  );
}

export default ZoomableCanvasPage;
